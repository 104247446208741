import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pic1 from '../assets/images/pic05.jpg';
import pic2 from '../assets/images/pic04_resize.jpg';
import pic3 from '../assets/images/pic03.jpg';
import Pdf from '../assets/docs/Welcome.pdf';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <h2>
          We design and manage digital ad campaigns to get you more customers.
        </h2>
        <br />
        <a href= {Pdf} target="_blank" class="welcomePDFButton">Welcome Guide</a>      
      </header>

      <div className="box alt container">
        <section className="feature left">
          <a className="image icon fa-signal">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h3>Find your audience</h3>
            <p>
              Do you provide a service? What's your highest selling product? Our marketing team will work closely with you to understand your business
              and your target audience.
            </p>
          </div>
        </section>
        <section className="feature right">
          <a className="image icon fa-code">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h3>Grow your reach</h3>
            <p>
              Social media sites like Facebook and Instagram have grown into places of online business. Small to medium sized businesses can operate 
              entirely through these apps and we can help them succeed.
            </p>
          </div>
        </section>
        <section className="feature left">
          <a className="image icon fa-mobile">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h3>Monthly Insights</h3>
            <p>
              We provide monthly reports on your ad campaigns so that you can see the impact and impressions your business has made.
            </p>
          </div>
        </section>
      </div>

      <footer className="major container medium">
        <h3>Get a free consultation today</h3>
        <p>
          Ready to establish your online presence and bring in new customers? Contact us below:
        </p>
        <p><strong>Email:</strong> kogden@hyadvertising.com</p>
        <p><strong>Phone:</strong> (516) 512-2337</p>
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
