module.exports = {
  siteTitle: 'Heck Yes Advertising', // <title>
  manifestName: 'HYAds',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#3de0db',
  manifestThemeColor: '#3de0db',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/favicon-32x32.png',
  pathPrefix: `/hyads/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Heck Yes Advertising',
  subHeading: 'More customers. More sales. More business.',
  // social
  socialLinks: [
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/hyadvertising',
    },
    // {
    //   icon: 'fa-envelope-o',
    //   name: 'Email',
    //   url: 'mailto:kogden@hyadvertising.com',
    // },
  ],
};
