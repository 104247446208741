import React from 'react';
export default function ContactForm() {
  return (
    <form method="post" action="mailto:kogden@hyadvertising.com" name="emailForm" enctype="text/plain">
      <div className="row">
        {/* <div className="col-12 pb-0">
          <p>kogden@hyadvertising.com<br/>(516) 512-2337</p>
        </div> */}
      </div>
    </form>
  );
}
