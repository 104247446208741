import React from 'react';
import config from '../../config';
import icon from '../assets/images/icon1.png';

export default function Header() {
  return (
    <div id="header">
      <span className="logo icon">
        <img src={icon} id="hylogo"></img>
      </span>
      <h1>{config.heading}</h1>
      <p>{config.subHeading}</p>
    </div>
  );
}
